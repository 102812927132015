<template>
  <div class="sai-breadcrumbs">
    <div class="sai-breadcrumbs__top">
      <div class="sai-breadcrumbs__top__item" @click="move(0)">
        <div class="sai-breadcrumbs__top__item__text">
          {{ topText }}
        </div>
      </div>
    </div>
    <div id="sai-bread-content" class="sai-breadcrumbs__content scrollX">
      <div class="sai-breadcrumbs__content__items">
        <div
          class="sai-breadcrumbs__content__items__item"
          :class="['active', localIndex === index && 'selected']"
          v-for="(item, index) in list"
          v-if="index > 0"
          @click="move(index)"
          :style="{ 'z-index': 1000 - index }"
          :key="index"
        >
          <a class="sai-breadcrumbs__content__items__item__text active">
            <span>
              {{ generateTitle(item.text) }}
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadcrumbsMixin from '@/mixins/BreadcrumbsMixin';
import Component, { mixins } from 'vue-class-component';
import ProductTicketMixin from '../mixins/ProductTicketMixin';
import Const from '../const/index';

@Component({})
export default class Breadcrumbs extends mixins(
  BreadcrumbsMixin,
  ProductTicketMixin
  ) {
  scrollTarget = '.sai-breadcrumbs__content__items__item.selected';
  topText = Const.BREADCRUMB_TOP_TEXT;

  generateTitle(text) {
    return text.length > 10 ? `${text.slice(0, 10)}...` : text;
  }

  // For Customized Ticket
  generateClearTicket() {
    const startTime =
      this.$store.state.ticket.startTime || String(new Date().getTime());
    this.$store.commit('ticket/setStartTime', startTime);

    const newTicket = {
      origin: 'window',
      user_agent: this.$store.state.user.userAgent,
      user_id: this.$store.state.user.Id,
      start_time: startTime,
      end_time: String(new Date().getTime()),
      status: 'open',
    };

    return { ...newTicket, ...this.customizedTicket };
  }
  generateTicket(item, startTime, query, tagActiveSet) {
    let ticketParams = { items: item.items };
    switch (item.viewType) {
      case 'talkScript':
        ticketParams.status = 'open';
        break;
      case 'scenario':
        ticketParams.status = 'answering';
        break;
      case 'result':
        ticketParams.status = 'answered';
        ticketParams.status_feedback = 'open';
        break;
    }
    const commonTicket = {
      origin: 'window',
      user_agent: this.$store.state.user.userAgent,
      user_id: this.$store.state.user.Id,
      start_time: startTime,
      end_time: String(new Date().getTime()),
      query: query,
      tag_active_set: tagActiveSet,
      tag_used_set: this.$store.state.ticket.tagUsedSet,
      history_query: this.$store.state.ticket.historyQuery,
      history_action_faq_channel: this.$store.state.ticket
        .historyActionFaqChannel,
    };

    return { ...commonTicket, ...ticketParams, ...this.customizedTicket };
  }
}
</script>

<style lang="scss" scoped>
@import '../style/component/breadcrumbs';
</style>

<template>
  <div
    class="resolveEnquete"
    v-show="currentValue && currentValue.viewType === 'result'"
  >
    <div>
      <div class="resolveButtonArea">
        <div class="buttonWrapper">
          <a
            :class="['resolveButton', showLastMessage && 'disabled']"
            @click.prevent="resolved"
          >
            {{ resolveMessage }}
          </a>
          <a
            :class="['resolveButton', showLastMessage && 'disabled']"
            @click.prevent="unresolved"
          >
            {{ unsolvedMessage }}
          </a>
        </div>
      </div>
    </div>
    <div id="sai-reset"></div>
    <div v-show="showLastMessage">
      <div class="lastMessage caption" v-html="afterFeedbackMessage"></div>
      <div class="resolveButtonArea reset">
        <a @click.prevent="reset" class="resolveButton">{{ goToTopLabel }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import ResourceListFooterMixin from '@/mixins/ResourceListFooterMixin';
import ProductTicketMixin from '../mixins/ProductTicketMixin';
import Const from '../const/index';
import { eventBus } from '@/eventBus';

@Component({
  props: {
    withEnquete: Boolean,
    withSuggestion: Boolean,
  }
})
export default class ResourceListFooter extends mixins(
  ResourceListFooterMixin,
  ProductTicketMixin
) {
  resolveMessage = Const.ASK_FEEDBACK_CHOICES.RESOLVED;
  unsolvedMessage = Const.ASK_FEEDBACK_CHOICES.UNRESOLVED;
  afterResulvedMessage = Const.AFTER_RESOLVED_MESSAGE;
  afterUnResulvedMessage = Const.AFTER_UNRESOLVED_MESSAGE;
  goToTopLabel = Const.GO_TO_TOP_LABEL;

  get afterFeedbackMessage() {
    if (this.feedbackResult === 'unresolved') {
      return this.afterUnResulvedMessage;
    }
    return this.afterResulvedMessage;
  }

  async resolved() {
    this.feedbackResult = 'resolved';

    if (this.withEnquete) {
      this.$store.dispatch('enquete/updateContents', {
        type: Const.ENQUETE.TYPE.RESOLVED,
        action: Const.ENQUETE.ACTION.FEEDBACK_RESOLVED,
        item: this.currentValue
      });
      this.$store.dispatch('enquete/updateShow', true);
    }

    this.setShowLastMessage(true);
    setTimeout(() => {
      this.$emit('scrollBottom');
    }, 300);

    this.$store.commit('ticket/setResetFlag', true);
    const newTicket = this.updateTicket({
      item: this.currentValue,
      feedback: 'resolved',
    });
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }
  async unresolved() {
    this.feedbackResult = 'unresolved';

    if (this.withSuggestion) {
      this.$store.dispatch('enquete/updateContents', {
        type: Const.ENQUETE.TYPE.UNRESOLVED,
        action: Const.ENQUETE.ACTION.FEEDBACK_UNRESOLVED,
        item: this.currentValue
      });
      this.$store.dispatch('enquete/updateShow', true);
    }

    this.setShowLastMessage(true);
    setTimeout(() => {
      this.$emit('scrollBottom');
    }, 300);

    this.$store.commit('ticket/setResetFlag', true);
    const newTicket = this.updateTicket({
      item: this.currentValue,
      feedback: 'unresolved',
    });
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }

  async reset(e) {
    eventBus.$emit('resetWithScrollTop');
    if (this.$store.state.ticket.resetFlag) {
      eventBus.$emit('resetTicket');
      return;
    }
    const newTicket = this.generateClearTicket();
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }

  // For Customized Ticket
  generateTicket(item, startTime, query, tagActiveSet, feedback) {
    const commonTicket = {
      origin: 'window',
      user_agent: this.$store.state.user.userAgent,
      user_id: this.$store.state.user.Id,
      start_time: startTime,
      end_time: String(new Date().getTime()),
      query: query,
      tag_active_set: tagActiveSet,
      tag_used_set: this.$store.state.ticket.tagUsedSet,
      history_query: this.$store.state.ticket.historyQuery,
      history_action_faq_channel: this.$store.state.ticket
        .historyActionFaqChannel,
    };

    let newTicket = {
      ...commonTicket,
      status: 'answered',
      items: item.items,
      status_feedback: 'done',
      feedback: feedback,
    };

    return { ...newTicket, ...this.customizedTicket };
  }
  generateClearTicket() {
    const startTime =
      this.$store.state.ticket.startTime || String(new Date().getTime());
    this.$store.commit('ticket/setStartTime', startTime);
    const keywordTags = this.$store.getters['tagSearch/selectedKeywordTags'];
    const tagActiveSet =
      keywordTags.length === 0
        ? []
        : keywordTags.map(t => {
            const v = { id: t.id, text: t.text };
            if (t.displayText) {
              v.label = t.displayText;
            }
            return v;
          });
    if (
      keywordTags.length > 0 &&
      this.$store.state.ticket.tagUsedSet.length === 0
    ) {
      this.$store.commit('ticket/setTagUsedSet', tagActiveSet);
    }
    const query = this.$store.state.tagSearch.searchText;
    if (query && this.$store.state.ticket.historyQuery.length === 0) {
      this.$store.commit('ticket/setHistoryQuery', [query]);
    }
    const commonTicket = {
      origin: 'window',
      user_agent: this.$store.state.user.userAgent,
      user_id: this.$store.state.user.Id,
      start_time: startTime,
      end_time: String(new Date().getTime()),
      query: query,
      tag_active_set: tagActiveSet,
      tag_used_set: this.$store.state.ticket.tagUsedSet,
      history_query: this.$store.state.ticket.historyQuery,
      history_action_faq_channel: this.$store.state.ticket
        .historyActionFaqChannel,
    };

    const newTicket = {
      ...commonTicket,
      status: 'open',
    };

    return { ...newTicket, ...this.customizedTicket };
  }
}
</script>

<style lang="scss" scoped>
@import '../style/component/resourceListFooter';
</style>

<template>
  <div class="sai-tag-list">
    <div class="sai-tag-list__head">
      <div class="sai-tag-list__head__title">
        <div class="sai-tag-list__head__title__text">
          {{ tagTitle }}
        </div>
      </div>
      <div class="sai-tag-list__tags" v-if="!$store.state.user.isSP">
        <a
          class="sai-tag-list__tags__tag"
          v-for="(candidate, index) in filteredCandidateTags"
          v-bind:key="index"
          v-if="index < numberOfKeywordsDisplayed && candidate.tag"
          @click.prevent="selectTag(candidate)"
        >
          <span class="sai-tag-list__tags__tag__content">
            {{ convertTagText(candidate) }}
          </span>
        </a>
      </div>
    </div>
    <div class="sai-tag-list__tags" v-if="$store.state.user.isSP">
      <a
        class="sai-tag-list__tags__tag"
        v-for="(candidate, index) in filteredCandidateTags"
        v-bind:key="index"
        v-if="index < numberOfKeywordsDisplayed && candidate.tag"
        @click.prevent="selectTag(candidate)"
      >
        <span class="sai-tag-list__tags__tag__content">
          {{ convertTagText(candidate) }}
        </span>
      </a>
    </div>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import TagListMixin from '@/mixins/TagListMixin';
import ProductTicketMixin from '../mixins/ProductTicketMixin';
import Const from '../const/index';

@Component({})
export default class TagList extends mixins(
  TagListMixin,
  ProductTicketMixin
  ) {
  get tagTitle() {
    return this.inputEmpty
      ? Const.TAG_POPULAR_TITLE
      : Const.TAG_RECOMMEND_TITLE;
  }
  get numberOfKeywordsDisplayed() {
    return this.$store.state.user.isSP
      ? Const.NUMBER_OF_KEYWORDS_DISPLAYED.SP
      : Const.NUMBER_OF_KEYWORDS_DISPLAYED.PC;
  }

  // For Customized Ticket
  generateTicket(startTime, query, tagActiveSet) {
    const newTicket = {
      origin: 'window',
      user_agent: this.$store.state.user.userAgent,
      user_id: this.$store.state.user.Id,
      start_time: startTime,
      end_time: String(new Date().getTime()),
      query: query,
      tag_active_set: tagActiveSet,
      tag_used_set: this.$store.state.ticket.tagUsedSet,
      history_query: this.$store.state.ticket.historyQuery,
      history_action_faq_channel: this.$store.state.ticket
        .historyActionFaqChannel,
      status: 'open',
    };

    return { ...newTicket, ...this.customizedTicket };
  }
}
</script>

<style lang="scss" scoped>
@import '../style/component/tagList';
</style>

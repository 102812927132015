export default {
  namespaced: true,
  state() {
    return {
      show: false,
      type: '',
      action: '',
      item: {},
    };
  },
  mutations: {
    setShow(state, payload) {
      state.show = payload;
    },
    setType(state, payload) {
      state.type = payload;
    },
    setAction(state, payload) {
      state.action = payload;
    },
    setItem(state, payload) {
      state.item = payload;
    },
  },
  actions: {
    updateShow({ commit }, boolean) {
      commit('setShow', boolean);
    },
    updateContents({ commit }, { type, action, item }) {
      commit('setType', type);
      commit('setAction', action);
      commit('setItem', item);
    },
    reset({ commit }) {
      commit('setShow', false);
      commit('setType', '');
      commit('setAction', '');
      commit('setItem', {});
    }
  },
};

import { render, staticRenderFns } from "./ResourceListFooter.vue?vue&type=template&id=ecb281e6&scoped=true&"
import script from "./ResourceListFooter.vue?vue&type=script&lang=js&"
export * from "./ResourceListFooter.vue?vue&type=script&lang=js&"
import style0 from "./ResourceListFooter.vue?vue&type=style&index=0&id=ecb281e6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ecb281e6",
  null
  
)

export default component.exports